import axios from 'axios'

const apiClient = axios.create({
	baseURL: `${process.env.VUE_APP_API_URL}/api`,
	timeout: 1000 * 10,
})

apiClient.interceptors.request.use((request) => {
	request.headers = {
		...request.headers,
		'Access-Control-Allow-Origin': true,
	}
	return request
})

export default apiClient
