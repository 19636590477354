import Vue from 'vue'
import Vuex from 'vuex'
//
import * as api from '@/services/billing'
import utilities from '@/services/utilities'
import Swal from 'sweetalert2'
import fs from 'fs'

Vue.use(Vuex)

export default {
	namespaced: true,
	state: {
		spinnerLoader: false,
		spinnerLoaderLabel: 'Procesando información',
		invoice: '',
		currentStep: 0,
	},
	mutations: {
		SET_STATE(state, payload) {
			Object.assign(state, {
				...payload,
			})
		},
	},
	actions: {
		VALIDATE_TICKET({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			return new Promise((resolve, reject) => {
				api
					.validateTicket(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		CREATE_BILL({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			return new Promise((resolve, reject) => {
				api
					.createBill(payload)
					.then((response) => {
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
		DOWNLOAD_BILL({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			api
				.downloadBill(payload)
				.then((response) => {
					console.log('downloadBill-->', response)
					let { pdfStream } = response.data

					const file = fs.createWriteStream('./factura.pdf')
					pdfStream.pipe(file)
				})
				.catch((error) => {
					Swal.fire({
						title: 'Facturación',
						html: utilities.objectValidate(error, 'response.data.message', 'Error al obtener la factura.'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
				})
				.finally(() => {
					commit('SET_STATE', {
						spinnerLoader: false,
					})
				})
		},
		async SEARCH_RFC({ commit }, payload) {
			commit('SET_STATE', {
				spinnerLoader: true,
			})

			return new Promise((resolve, reject) => {
				api
					.searchRFC(payload)
					.then((response) => {
						// console.log('searchRFC-->', response)
						resolve(response)
					})
					.catch((error) => {
						console.log(utilities.objectValidate(error, 'response.data.message', 'No hay RFC asociado'))
						reject(error)
					})
					.finally(() => {
						commit('SET_STATE', {
							spinnerLoader: false,
						})
					})
			})
		},
	},
	getters: {
		invoice: (state) => state.invoice,
		spinnerLoaderLabel: (state) => state.spinnerLoaderLabel,
		spinnerLoader: (state) => state.spinnerLoader,
	},
}
