import Vue from 'vue'
import Vuex from 'vuex'
import settings from './settings'
import billing from './billing'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		settings,
		billing,
	},
	state: {},
	mutations: {},
	actions: {},
})
